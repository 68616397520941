<template>
	<v-container
			fluid
	>
		<v-row align="center">
			<DateRangeSelector @selected="newDateRange" />
			<div class="pl-4 grey--text">{{dateRangeText}}</div>
			<v-spacer></v-spacer>
			<v-menu offset-y>
				<template v-slot:activator="{ on }">
					<Button v-on="on" class="ml-4">
						<v-icon>mdi-download</v-icon>
					</Button>
				</template>
				<v-list>
					<v-list-item @click="exportUnansweredReviews" v-if="$authorized([`manager`,`marketing`])">
						<v-list-item-title>Unanswered Reviews</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-row>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-row no-gutters align="center">
							<v-col sm="2">
								<v-select
										v-on:change="refreshContent"
										v-model="type"
										:items="['All','post','article','review','love','idea','warning','pledge']"
										label="Type"
								/>
							</v-col>
							<v-col sm="1">
								<v-select
										v-on:change="refreshContent"
										v-model="level"
										:items="['All','1','2','3','4','5']"
										label="Level"
								/>
							</v-col>
							<v-col sm="2">
								<v-select
										v-on:change="refreshContent"
										v-model="language"
										:items="languages"
										item-key="value"
										item-text="name"
										label="Language"
								/>
							</v-col>
						</v-row>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								item-key="_id"
								class="row-pointer"
								:search="search"
								:options.sync="options"
								@click:row="goToPost"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field no-gutters clearable v-model="search" label="Search" />
									</v-col>
								</v-row>
							</template>
							<template v-slot:[`item.author`]="{ item }">
								<span class="text-no-wrap">{{ item.author && item.author.at_name }}</span>
							</template>
							<template v-slot:[`item.date_created`]="{ item }">
								<span class="text-no-wrap">{{ dayjs(item.date_created).format(`YYYY-MM-DD HH:mm`) }}</span>
							</template>
							<template v-slot:[`item.title`]="{ item }">
								<span>{{ item.title || `` | ellipsis(50) }}</span>
							</template>
							<template v-slot:[`item.text`]="{ item }">
								<span>{{ item.text | ellipsis(100) }}</span>
							</template>
							<template v-slot:[`item.type`]="{ item }">
								<svg-icon v-if="item.type === `review` && item.review.type === `idea`" icon="idea" />
								<svg-icon v-else-if="item.type === `review` && item.review.type === `love`" icon="love" />
								<svg-icon v-else-if="item.type === `review` && item.review.type === `warning`" icon="warning" />
								<svg-icon v-else-if="item.type === `pledge`" icon="pledge" />
								<svg-icon v-else-if="item.type === `post`" icon="pencil" />
								<svg-icon v-else-if="item.type === `article`" icon="article" />
								<span v-else>{{ item.type }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import DateRangeSelector from '@/components/material/DateRangeSelector'

	export default {
		components: {
			DateRangeSelector,
		},
		data () {
			return {
			loading: false,
			language: null,
			languages: [
				{name:`All`,value:``},
				{name:`Danish`,value:`da`},
				{name:`Deutsch`,value:`de`},
				{name:`English`,value:`en`},
				{name:`Russian`,value:`ru`},
				{name:`Spain`,value:`es`},
				{name:`Swedish`,value:`sv`},
				{name:`Undefined`,value:`und`},
			],
			search: ``,
			options: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			headers: [
				{ text: ``, value: `type` },
				{ text: `Author`, value: `author` },
				{ text: `Title`, value: `title` },
				{ text: `Text`, value: `text` },
				{ text: `Level`, value: `level` },
				{ text: `Lang`, value: `language` },
				{ text: `Posted`, value: `date_created` },
			],
			totalItems: 0,
			items: [],
			type: null,
			level: null,
			dateRange: ``,
			dateRangeParams: ``,
			dateRangeName: ``,
			dateRangeText: ``,
			}
		},
		methods: {
			newDateRange(o) {
				this.dateRange = o.date_range
				this.dateRangeParams = o.params
				this.dateRangeName = o.name
				this.refreshContent()
			},
			goToPost(value) {
				this.$router.push({name: `Post`, params: {id: value._id}})
			},
			openUrl(url) {
				this.openInBrowser(this.$appUrl + url)
			},
			exportUnansweredReviews() {
				window.open(this.$httpInt.defaults.baseURL + `/v2/app/posts/unanswered-reviews?export=xlsx&${this.dateRangeParams}`)
			},
			refreshContent() {
				let additional = {}
				if (this.language) additional.language = this.language
				if (this.level && this.level !== `All`) additional.level = this.level
				if (this.type && this.type !== `All`) additional.type = this.type

				this.loading = true
				this.$httpInt.get(`/v2/app/posts/?${this.dateRangeParams}`, { params: this.$paginationParams(this, additional) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		name: `CommunityPosts`,
		watch: {
			search: {
				handler (value) {
					if (value && value.length < 4) return
					this.$debounce(() => this.refreshContent(), 300)
				},
			},
			options: {
				handler () {
					this.refreshContent()
				},
			},
		},
	}
</script>
