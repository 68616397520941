var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('DateRangeSelector',{on:{"selected":_vm.newDateRange}}),_c('div',{staticClass:"pl-4 grey--text"},[_vm._v(_vm._s(_vm.dateRangeText))]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('Button',_vm._g({staticClass:"ml-4"},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('v-list',[(_vm.$authorized(["manager","marketing"]))?_c('v-list-item',{on:{"click":_vm.exportUnansweredReviews}},[_c('v-list-item-title',[_vm._v("Unanswered Reviews")])],1):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"sm":"2"}},[_c('v-select',{attrs:{"items":['All','post','article','review','love','idea','warning','pledge'],"label":"Type"},on:{"change":_vm.refreshContent},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-select',{attrs:{"items":['All','1','2','3','4','5'],"label":"Level"},on:{"change":_vm.refreshContent},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-select',{attrs:{"items":_vm.languages,"item-key":"value","item-text":"name","label":"Language"},on:{"change":_vm.refreshContent},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"item-key":"_id","search":_vm.search,"options":_vm.options,"footer-props":{'items-per-page-options':[10,25,50]}},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToPost},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"no-gutters":"","clearable":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.author",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.author && item.author.at_name))])]}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dayjs(item.date_created).format("YYYY-MM-DD HH:mm")))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("ellipsis")(item.title || "",50)))])]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("ellipsis")(item.text,100)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === "review" && item.review.type === "idea")?_c('svg-icon',{attrs:{"icon":"idea"}}):(item.type === "review" && item.review.type === "love")?_c('svg-icon',{attrs:{"icon":"love"}}):(item.type === "review" && item.review.type === "warning")?_c('svg-icon',{attrs:{"icon":"warning"}}):(item.type === "pledge")?_c('svg-icon',{attrs:{"icon":"pledge"}}):(item.type === "post")?_c('svg-icon',{attrs:{"icon":"pencil"}}):(item.type === "article")?_c('svg-icon',{attrs:{"icon":"article"}}):_c('span',[_vm._v(_vm._s(item.type))])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }